import { useQuery } from "react-query";
import { useAxios } from "./axios";
import { useAxiosSearch } from "./axiosSearch";

const useGetAllSearchResult = ({ filters, enabled, setSearchResultList }) => {
  const axios = useAxiosSearch();
  return useQuery(
    ["search", "result", { ...filters }],
    async () => {
      if (filters.query) {
        setSearchResultList(null);
        const { data } = await axios.get("/search/v1/", {
          params: {
            query: filters?.query ?? "",
            pageSize: filters?.pageSize ?? "",
            page: 0,
          },
        });
        setSearchResultList(data?.data);
        return data?.data;
      }
    },
    { enabled: !!enabled }
  );
};
export default useGetAllSearchResult;
